<template></template>


<script>
    import Cookie from '../../services/cookie'
    export default{

        mounted(){
            this.$http.post('odontodocs/logout').then(() =>{

                Cookie.deleteToken()

                this.$router.push( { name: 'login' } )

            })

        }
    }

</script>

<style></style>
